import React, { FC } from "react";
import {Wrapper} from "./HeroPlus1.styled";
import Hero from "Components/Hero";
import Item from "Components/Item";
import { SectionComponent } from "../types";
import {getCropData} from "../getCropData";


const HeroPlus1: FC<SectionComponent> = ({ data }) => {
  const [mainArticle, ...articles] = data.articles;

  return (
    <Wrapper>
      <Hero size={1} article={mainArticle} cropData={getCropData(mainArticle, "fourbythree")} />
        {articles.map((article, index) => (
          <Item
            key={index}
            size={1}
            article={article}
            cropData={getCropData(article, "onebyone")}
            showLead={true}
          />
        ))}
    </Wrapper>
  );
};

export default HeroPlus1;
